import GamestarApp from '../assets/images/game-night.png';
// import Appstore from '../assets/images/appstore.png';
// import GooglePlay from '../assets/images/google-play.png';
import FadeInElement from '../components/FadeInElement';
import useWidth from '../useWidth';

const GameNight = () => {
    const windowWidth = useWidth();

    return (
        <section id='game-night'>
            <div className='container'>
                <FadeInElement direction='right'>
                    <h2>The Netflix of Game Night</h2>
                </FadeInElement>
                <div className='content'>
                    <FadeInElement direction={windowWidth > 1120 ? 'down' : 'left'}>
                        <p>
                            Gamestar+ is truly the Netflix of Game Night. Gamestar+
                            connects users with a growing catalog of well-known games,
                            providing an immersive experience backed by innovative
                            technology on a transparent blockchain with access to a
                            content-rich ecosystem including board, card, dice,
                            educational, adult, themed, and family games among others.
                        </p>
                    </FadeInElement>
                    <img src={GamestarApp} alt='Gamestar application' />
                    {/* <FadeInElement direction='up' className='get-app'>
                        <img src={Appstore} alt='App store' />
                        <img src={GooglePlay} alt='Google Playstore' />
                    </FadeInElement> */}
                </div>
            </div>
        </section>
    );
};
export default GameNight;
