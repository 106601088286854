import HyperEfficientIcon from '../assets/Highlights/hyper-efficient.png';
import GloballyRecognisedIcon from '../assets/Highlights/globally-recognised.png';
import EstablishedMarketingIcon from '../assets/Highlights/established-marketing.png';
import FoundersIcon from '../assets/Highlights/founders.png';
import TokenUtilityIcon from '../assets/Highlights/token-utility.png';
import VeteranLeadershipIcon from '../assets/Highlights/veteran-leadership.png';
// import movies from '../assets/images/highlights.png';
import family from '../assets/images/family-hangout.png';
import FadeInElement from '../components/FadeInElement';

const Highlights = () => (
    <section id='highlights'>
        <div className='container'>
            <FadeInElement direction='right'>
                <h2>Highlights</h2>
            </FadeInElement>
            <div className='content'>
                {highlights.map(({ label, icon }) => (
                    <FadeInElement className='highlight' key={label}>
                        <div className='icon'>
                            <img src={icon} alt={label} />
                        </div>
                        <span dangerouslySetInnerHTML={{ __html: label }} />
                    </FadeInElement>
                ))}
            </div>
            {/* <FadeInElement direction='right'>
                <img className='movies' src={movies} alt='List of movies' />
            </FadeInElement>
            <div className='skewed-background'></div> */}
        </div>
        <img className='family' src={family} alt='family hanging out' />
    </section>
);

export default Highlights;

const highlights = [
    {
        label: 'Hyper-Efficient <br />Content Mode',
        icon: HyperEfficientIcon,
    },
    {
        label: 'Globally Recognized <br />Intellectual Property',
        icon: GloballyRecognisedIcon,
    },
    {
        label: 'Established Marketing <br />& Distribution Channels',
        icon: EstablishedMarketingIcon,
    },
    {
        label: 'Celebrity Founders / <br/>Strategic Investors',
        icon: FoundersIcon,
    },
    {
        label: 'High Token Utility',
        icon: TokenUtilityIcon,
    },
    {
        label: 'Veteran Leadership <br/ >Team',
        icon: VeteranLeadershipIcon,
    },
];
