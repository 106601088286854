import { useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import Gamestar_JD_TechnicalProductLead from './assets/documents/Gamestar_JD_TechnicalProductLead.pdf';

const Careers = () => {
    useEffect(() => {
        window.open(Gamestar_JD_TechnicalProductLead, '_self');
    }, []);

    return <Redirect to='/' />;
};

export default Careers;
