const TelegramIcon = () => (
    <svg
        width='52'
        height='52'
        viewBox='0 0 52 52'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
    >
        <circle cx='25.1875' cy='26.8125' r='21.9375' fill='white' />
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M26 52C40.3594 52 52 40.3594 52 26C52 11.6406 40.3594 0 26 0C11.6406 0 0 11.6406 0 26C0 40.3594 11.6406 52 26 52ZM21.4724 35.1161L21.9025 29.3087L32.982 19.7585C33.4641 19.3487 32.878 19.122 32.2334 19.5318L18.5365 27.7592L12.6398 25.9984C11.3571 25.6168 11.3333 24.7744 12.9074 24.1878L35.9721 15.7108C37.0403 15.3291 37.9741 15.9375 37.6264 17.4934L37.6253 17.4944L33.7014 35.1452C33.4056 36.3911 32.6332 36.7 31.5368 36.1134L25.5588 31.9108L22.6739 34.5576C22.3543 34.8623 22.0878 35.1161 21.4724 35.1161Z'
            fill='#008BDA'
        />
    </svg>
);

export default TelegramIcon;
