// import FacultyLab from '../assets/IncubationProjects/faculty-lab.png';
// import AllianceBlock from '../assets/IncubationProjects/alliance-block.png';
// import Unido from '../assets/IncubationProjects/unido.png';
// import Bridge from '../assets/IncubationProjects/bridge.png';
// import Holoride from '../assets/IncubationProjects/holoride.png';
// import IXSwap from '../assets/IncubationProjects/ix-swap.png';
// import Wallfair from '../assets/IncubationProjects/wallfair.png';
// import ORE from '../assets/IncubationProjects/ore-network.png';
// import Savage from '../assets/IncubationProjects/savage.png';
import TelegramIcon from '../assets/icons/Telegram';
import TwitterIcon from '../assets/icons/Twitter';
import MediumIcon from '../assets/icons/Medium';
import ExternalLink from '../components/ExternalLink';
import LitepaperButton from '../components/LitepaperButton';
import KycButton from '../components/KycButton';
import Discord from '../assets/icons/Discord';
// import TiktokIcon from '../assets/icons/Tiktok';

const Header = () => (
    <header>
        <div className='container'>
            <div className='links-container'>
                {/* <ul className='nav-buttons'> */}
                <LitepaperButton />
                <KycButton />
                {/* </ul> */}

                <div className='social-medias'>
                    {Object.entries(socialMediaLinks).map(([name, { icon, link }]) => (
                        <ExternalLink key={name} to={link}>
                            {icon}
                        </ExternalLink>
                    ))}
                </div>
            </div>
            {/* <div className='incubation'>
                <img src={FacultyLab} alt='Faculty lab' />
                <span>Incubated by Faculty Lab</span>
                <div className='projects'>
                    {projects.map(({ name, logo }) => (
                        <img key={name} src={logo} alt={name} />
                    ))}
                </div>
            </div> */}
        </div>
    </header>
);

export default Header;

const socialMediaLinks = {
    Discord: {
        icon: <Discord />,
        link: 'http://discord.gg/gamestarplus',
    },
    Telegram: {
        icon: <TelegramIcon />,
        link: 'https://t.me/GamestarPlusOfficial',
    },
    Twitter: {
        icon: <TwitterIcon />,
        link: 'https://twitter.com/gamestarplus',
    },
    Medium: {
        icon: <MediumIcon />,
        link: 'https://gamestarplus.medium.com/',
    },
    // TikTok: {
    //     icon: <TiktokIcon />,
    //     link: 'https://www.tiktok.com/@gamestarplusofficial',
    // },
};

// const projects = [
//     {
//         name: 'Alliance Block',
//         logo: AllianceBlock,
//     },
//     {
//         name: 'Unido',
//         logo: Unido,
//     },
//     {
//         name: 'Bridge Mutual',
//         logo: Bridge,
//     },
//     {
//         name: 'Holoride',
//         logo: Holoride,
//     },
//     {
//         name: 'IX Swap',
//         logo: IXSwap,
//     },
//     {
//         name: 'Wallfair',
//         logo: Wallfair,
//     },
//     {
//         name: 'ORE Network',
//         logo: ORE,
//     },
//     {
//         name: 'Savage',
//         logo: Savage,
//     },
// ];
