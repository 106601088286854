import './app.scss';
import { Route, BrowserRouter as Router, Switch, Redirect } from 'react-router-dom';
import Advisors from './sections/Advisors';
import Blockchain from './sections/Blockchain';
import Header from './sections/Header';
import GameNight from './sections/GameNight';
import Hero from './sections/Hero';
import Highlights from './sections/Highlights';
import IntellectualProperty from './sections/IntellectualProperty';
import LeadershipTeam from './sections/LeadershipTeam';
import Roadmap from './sections/Roadmap';
import Token from './sections/Token';
import Careers from './Careers';

function App() {
    return (
        <div className='App'>
            <Router>
                <Header />
                <Switch>
                    <Route path='/' exact component={Home} />
                    <Route path='/careers/tpl' exact component={Careers} />
                    <Redirect to='/' />
                </Switch>
            </Router>
        </div>
    );
}

export default App;

const Home = () => (
    <main>
        <Hero />
        <GameNight />
        <IntellectualProperty />
        <Highlights />
        <Blockchain />
        <Token />
        
        {/*<Roadmap />
        <LeadershipTeam />
        <Advisors />*/}
    </main>
);
