import intellectualProperties from '../assets/images/intellectual-property.png';
import FadeInElement from '../components/FadeInElement';

const IntellectualProperty = () => (
    <section id='intellectual-property'>
        <div className='container'>
            <FadeInElement direction='right'>
                <h2>Intellectual Property</h2>
            </FadeInElement>
            <FadeInElement direction='left'>
                <img src={intellectualProperties} alt='Intellectual Properties' />
            </FadeInElement>
            <p>
                Gamestar+ already has the rights to some of the most popular gaming titles
                and is in negotiations to secure rights to the most recognized brands in
                the world.
            </p>
        </div>
    </section>
);

export default IntellectualProperty;
