import Logo from '../assets/images/logo.png';
import VerticalWaveLine from '../assets/images/svg/VerticalWaveLine';
import Trophy from '../assets/images/trophy.png';
import SteveHarvey from '../assets/images/steve.png';
import HorizontalWaveLine from '../assets/images/svg/HorizontalWaveLine';
import FadeInElement from '../components/FadeInElement';
// import LitepaperButton from '../components/LitepaperButton';
import KycButton2 from '../components/kycButton2';

const Hero = () => (
    <section id='hero'>
        <div className='container'>
            <div className='logo-container'>
                <img src={Logo} alt='Gamestar Logo' />
            </div>
            <div className='description'>
                <FadeInElement>
                    <p>
                        The future of game night is here with
                        <br />
                        Streaming Games On-Demand!
                    </p>
                </FadeInElement>
                <FadeInElement>
                    <span>Powered by GSTAR</span>
                </FadeInElement>
            </div>
            <div className='opportunity-and-solution'>
                <VerticalWaveLine />
                <div className='opportunity'>
                    <FadeInElement>
                        <h3>The Opportunity</h3>
                    </FadeInElement>
                    <FadeInElement>
                        <p>
                            Over 50% of households play board games at least once per
                            week, yet the board game format has hardly changed in over a
                            century. An industry worth over $16 billion has yet to have
                            it’s ‘Netflix moment’. Until now...
                        </p>
                    </FadeInElement>
                </div>
                <div className='solution'>
                    <FadeInElement>
                        <h3>The Solution</h3>
                    </FadeInElement>
                    <FadeInElement>
                        <p>
                            Gamestar+ is a next-generation streaming app that combines an
                            innovative ‘play to earn’ blockchain architecture with
                            globally recognized brands to deliver unique social gaming
                            content through TV, mobile, and connected devices.
                        </p>
                    </FadeInElement>
                    <FadeInElement>
                        <p>
                            In the same way that Netflix transformed home entertainment,
                            and Spotify transformed the music industry, Gamestar+ is
                            poised to revolutionize board games by ‘bringing the metaverse
                            to the lounge room’ and turning the games we know and love
                            into interactive multimedia experiences.
                        </p>
                    </FadeInElement>
                </div>
                {/* <LitepaperButton /> */}
                <KycButton2 />
            </div>
            <div className='about-steve'>
                <FadeInElement direction='right' className='trophy-container'>
                    <img src={Trophy} alt='Trophy' className='trophy' />
                </FadeInElement>
                <div className='about'>
                    <span>Steve Harvey</span>
                    <span className='title'>Founding Partner</span>
                    <div className='line'>
                        <HorizontalWaveLine />
                    </div>
                </div>
                <FadeInElement direction='left'>
                    <ul>
                        <li>The King of TV Entertainment</li>
                        <li>11 Time Emmy winner</li>
                        <li>Top 5 radio show in America</li>
                        <li>40 million followers</li>
                    </ul>
                </FadeInElement>
            </div>
            <img src={SteveHarvey} alt='Steve Harvey' className='steve' />
        </div>
    </section>
);

export default Hero;
