import ExternalLink from './ExternalLink';

const KycButton2 = () => (
    <ExternalLink
    className="litepaper-button"
    to="https://app.fractal.id/authorize?client_id=q4Rwmq3m64j2IvkNByHGV8quQ3SHyatNLD_mTwTQCkA&redirect_uri=https%3A%2F%2Fwww.blockchain.gamestarplus.com%2Fcallback&response_type=code&scope=contact%3Aread%20verification.basic%3Aread%20verification.basic.details%3Aread%20verification.liveness%3Aread%20verification.liveness.details%3Aread%20verification.wallet%3Aread%20verification.wallet.details%3Aread"
  >
    Early KYC Whitelist
  </ExternalLink>
);

export default KycButton2;
