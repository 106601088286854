import { useEffect, useRef, useState } from 'react';

const directions = ['up', 'down', 'left', 'right'];

const FadeInElement = ({ className = '', direction = 'up', children }) => {
    const [isVisible, setVisible] = useState(false);

    const domRef = useRef();

    useEffect(() => {
        const element = domRef.current;

        const observer = new IntersectionObserver(([entry]) => {
            setVisible(entry.isIntersecting);

            if (entry.isIntersecting) {
                observer.unobserve(element);
            }
        });

        element && observer.observe(element);

        return () => observer.unobserve(element);
    }, []);

    return (
        <div
            ref={domRef}
            className={`fade-in-element ${className} ${
                directions.indexOf(directions) ? direction : 'up'
            } ${isVisible ? 'in-view' : ''}`}
        >
            {children}
        </div>
    );
};

export default FadeInElement;
