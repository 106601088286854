import tokenUtility from '../assets/images/token-utility.png';
import tokenUtilityMobile from '../assets/images/token-utility-mobile.png';
import tokenMetric from '../assets/images/token-metric.png';
import donutChart from '../assets/images/chart.png';
import FadeInElement from '../components/FadeInElement';
import { Fragment } from 'react/cjs/react.production.min';

const tokenMetricHeader = [
    '',
    'Public Sale',
    'Strategic Sale',
    'Seed Sale',
    'Team',
    'Ecosystem',
    'Community',
    'Treasury',
    'Total',
];

const tokenMetrics = [
    [
        'Token Allocation',
        '250,000,000',
        '1,166,666,667',
        '750,000,000',
        '1,500,000,000',
        '2,400,000,000',
        '1,440,000,000',
        '2,493,333,333',
        '10,000,000,000',
    ],
    [
        '% of Total Supply',
        '2.50%',
        '11.67%',
        '7.50%',
        '15.00%',
        '24.00%',
        '14.40%',
        '24.93%',
        '100.00%',
    ],
    ['Token Price ($)', '$0.004', '$0.003', '$0.002', '-', '-', '-', '-', '-'],
    [
        'Amount Raised ($)',
        '$1,000,000',
        '$3,500,000',
        '$1,500,000',
        '-',
        '-',
        '-',
        '-',
        '$6,000,000',
    ],
    [
        'Total Valuation at each round ($)',
        '$40,000,000',
        '$30,000,000',
        '$20,000,000',
        '-',
        '-',
        '-',
        '-',
        '$32,222,222',
    ],
    ['Vest at TGE', '20.00%', '4.00%', '5.00%', '-', '4.00%', '-', '-', ''],
    ['Cliff (Months)', '0', '6', '9', '12', '1', '1', '3', ''],
    ['Vest (Months)', '5', '12', '12', '30', '48', '24', '24', ''],
];

const Token = () => (
    <section id='token'>
        <div className='container'>
            <div className='token-utility'>
                <FadeInElement className='right'>
                    <h2>Token Utility</h2>
                </FadeInElement>
                <FadeInElement direction='left'>
                    <picture>
                        <source media='(max-width:900px)' srcSet={tokenUtilityMobile} />

                        <img src={tokenUtility} alt='Token utility' />
                    </picture>
                </FadeInElement>
            </div>
            <div className='token-metrics'>
                <FadeInElement direction='right'>
                    <h2>Token Metrics</h2>
                </FadeInElement>
                <div className='content'>
                    <FadeInElement direction='left' className='table'>
                        <div className='header'>
                            <div />
                            {tokenMetricHeader.map((header, index) => (
                                <Fragment key={index}>
                                    <div className='default'>{header}</div>
                                    <div>{tokenMetrics?.[index]?.[0]}</div>
                                </Fragment>
                            ))}
                            <div className='border' />
                        </div>
                        {tokenMetrics.map((row, rowIndex) => (
                            <div className='row' key={rowIndex}>
                                {row.map((column, columnIndex) => (
                                    <div key={columnIndex}>
                                        {columnIndex === 0 ? (
                                            <>
                                                <div className='legend-color' />
                                                <span>
                                                    {tokenMetricHeader[rowIndex + 1]}
                                                </span>
                                                <span className='default'>{column}</span>
                                            </>
                                        ) : (
                                            <>
                                                <span className='default'>{column}</span>
                                                <span>
                                                    {
                                                        tokenMetrics?.[columnIndex - 1]?.[
                                                            rowIndex + 1
                                                        ]
                                                    }
                                                </span>
                                            </>
                                        )}
                                    </div>
                                ))}
                                <div className='border' />
                            </div>
                        ))}
                    </FadeInElement>
                    <FadeInElement direction='right' className='total-supply'>
                        <div className='token-details'>
                            <div className='ticker'>
                                <p>
                                    Token Ticker: <span>GSTAR</span>
                                </p>
                            </div>
                            <div className='supply'>
                                <p>
                                    Total Supply: <span>10,000,000,000</span>
                                </p>
                            </div>
                        </div>

                        <picture>
                            <source media='(max-width:1120px)' srcSet={donutChart} />
                            <img src={tokenMetric} alt='Token metrics' />
                        </picture>
                        <div className='text'>Total Supply</div>
                        <div className='value'>10,000,000,000</div>
                    </FadeInElement>
                </div>
            </div>
        </div>
    </section>
);

export default Token;
