import blockchain from '../assets/images/blockchain.png';
import FadeInElement from '../components/FadeInElement';

const Blockchain = () => (
    <section id='blockchain'>
        <div className='container'>
            <FadeInElement direction='right'>
                <img src={blockchain} alt='blockchain' />
            </FadeInElement>
            <div className='content'>
                <FadeInElement direction='right'>
                    <h2>Blockchain</h2>
                </FadeInElement>
                <FadeInElement>
                    <p>
                        Core to Gamestar+ is the GSTAR token, an in-game blockchain-based
                        currency that can be used for a multitude of purposes including
                        in-app purchases, skins, level unlocks, premium content, and much
                        more.
                    </p>
                </FadeInElement>
                <FadeInElement>
                    <p>
                        Gamestar+ is also underpinned by an NFT-powered ecosystem,
                        allowing content creators and stakeholders to transparently know
                        exactly how much their content has been used, and to be
                        compensated fairly for it.
                    </p>
                </FadeInElement>
                <FadeInElement>
                    <p>
                        It is intended that users receive NFTs by completing experiences
                        and earning achievements, or by buying them directly from the
                        Gamestar+ store using GSTAR tokens.
                    </p>
                </FadeInElement>
                <FadeInElement>
                    <p>
                        Developers may have full control over how they utilize NFTs within
                        the Gamestar+ ecosystem. They will be able to use NFTs to
                        incentivize users to try their content, or place them on a
                        storefront to create additional revenue.
                    </p>
                </FadeInElement>
            </div>
        </div>
    </section>
);

export default Blockchain;
