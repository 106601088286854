import ExternalLink from './ExternalLink';
// import Litepaper from '../assets/documents/litepaper.pdf';

const LitepaperButton = () => (
    <ExternalLink className='litepaper-button' to='https://gamestarplus.link/Litepaper'>
        Read our litepaper
    </ExternalLink>
);

export default LitepaperButton;
