const MediumIcon = () => (
    <svg
        width='51'
        height='52'
        viewBox='0 0 51 52'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
    >
        <circle cx='25.1875' cy='26.8125' r='21.9375' fill='white' />
        <path
            d='M25.6103 0C11.8253 0 0.650391 11.6407 0.650391 26.0001C0.650391 40.3595 11.8253 52 25.6103 52C39.3954 52 50.5703 40.3595 50.5703 26.0001C50.5703 11.6407 39.3954 0 25.6103 0ZM36.5304 19.6807H36.2273C36.2273 19.6807 35.317 19.6807 35.317 20.6289C35.317 21.5769 35.317 31.3719 35.317 31.3719C35.317 31.3719 35.317 32.3193 36.2273 32.3193H36.5304V34.8471H29.2504V32.3193H30.4636V20.5654H30.3423L26.624 34.8471H23.3494L19.665 20.5654H19.5437V32.3193H20.7571V34.8471H14.6903V32.3193H14.9934C14.9934 32.3193 15.9036 32.3193 15.9036 31.3713C15.9036 30.4233 15.9036 20.6281 15.9036 20.6281C15.9036 20.6281 15.9036 19.6807 14.9934 19.6807H14.6903V17.153H22.7396L25.5629 28.097H25.6559L28.5053 17.153H36.5304V19.6807V19.6807Z'
            fill='#008BDA'
        />
    </svg>
);

export default MediumIcon;
