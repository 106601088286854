const Discord = () => (
    <svg
        width='52'
        height='52'
        viewBox='0 0 52 52'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
    >
        <circle cx='25.1875' cy='26.8125' r='21.9375' fill='white' />
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M26 52C40.3594 52 52 40.3594 52 26C52 11.6406 40.3594 0 26 0C11.6406 0 0 11.6406 0 26C0 40.3594 11.6406 52 26 52ZM21.9731 14.6528C19.9035 15.012 17.924 15.6428 16.0727 16.5109C12.8356 21.3552 11.2273 26.8188 11.8211 33.1205C14.2978 34.9763 16.7003 36.1022 19.0622 36.839C19.6471 36.0354 20.1645 35.1789 20.6121 34.281C19.7596 33.9563 18.9408 33.5557 18.167 33.0883C18.3694 32.9364 18.5696 32.7775 18.7653 32.614C23.415 34.8198 28.5281 34.8198 33.2341 32.614C33.4275 32.7775 33.6277 32.9364 33.8324 33.0883C33.0608 33.5534 32.2443 33.954 31.3917 34.2787C31.8394 35.1789 32.359 36.0331 32.9416 36.8367C35.3013 36.0999 37.7015 34.974 40.1782 33.1205C40.6844 27.6845 39.6721 22.1702 35.9379 16.5155C34.0866 15.6451 32.1048 15.012 30.033 14.6528C29.7766 15.1133 29.4729 15.7326 29.2682 16.2254C27.1087 15.8938 24.9289 15.8938 22.7289 16.2254C22.5242 15.7326 22.2273 15.1133 21.9731 14.6528ZM33.3218 26.8464C33.3218 28.4558 32.1633 29.7774 30.7506 29.7774C29.3132 29.7774 28.1772 28.4558 28.1794 26.8464C28.1547 25.2347 29.3132 23.9132 30.7506 23.9132C32.188 23.9132 33.3218 25.237 33.3218 26.8464ZM23.8199 26.8464C23.8199 28.4558 22.6614 29.7774 21.2487 29.7774C19.8113 29.7774 18.6776 28.4558 18.6776 26.8464C18.6528 25.2347 19.8113 23.9132 21.2487 23.9132C22.6862 23.9132 23.8199 25.237 23.8199 26.8464Z'
            fill='#008BDA'
        />
    </svg>
);

export default Discord;
