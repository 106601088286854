const TwitterIcon = () => (
    <svg
        width='52'
        height='52'
        viewBox='0 0 52 52'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
    >
        <circle cx='25.1875' cy='26.8125' r='21.9375' fill='white' />
        <path
            d='M26 0C11.6404 0 0 11.6404 0 26C0 40.3596 11.6404 52 26 52C40.3596 52 52 40.3596 52 26C52 11.6404 40.3596 0 26 0ZM36.576 21.2983C36.5869 21.5204 36.5896 21.7425 36.5896 21.9592C36.5896 28.73 31.441 36.5327 22.0215 36.5327C19.129 36.5327 16.4369 35.6877 14.1727 34.2306C14.5708 34.2794 14.9798 34.2983 15.3942 34.2983C17.7937 34.2983 20.001 33.4831 21.7533 32.1073C19.5135 32.064 17.6231 30.5852 16.9704 28.554C17.2819 28.6108 17.6015 28.6433 17.9319 28.6433C18.3977 28.6433 18.8527 28.581 19.2806 28.4646C16.9379 27.9933 15.1721 25.9242 15.1721 23.4433V23.381C15.8627 23.7629 16.6535 23.9958 17.4931 24.0229C16.1173 23.1021 15.2154 21.5367 15.2154 19.7573C15.2154 18.8202 15.4673 17.9373 15.9087 17.1817C18.4356 20.28 22.2056 22.3167 26.4631 22.536C26.3737 22.1596 26.3304 21.7696 26.3304 21.366C26.3304 18.5412 28.6217 16.2446 31.4519 16.2446C32.9225 16.2446 34.255 16.8675 35.1894 17.8642C36.3567 17.6312 37.4508 17.2087 38.4394 16.621C38.0575 17.8154 37.2477 18.8202 36.1888 19.4567C37.226 19.3294 38.2119 19.0558 39.13 18.6469C38.4448 19.6733 37.5754 20.5779 36.576 21.2983Z'
            fill='#008BDA'
        />
    </svg>
);

export default TwitterIcon;
